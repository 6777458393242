import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AddAttendanceDialogComponent } from './pages/dialogs/add-attendance-dialog/add-attendance-dialog.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AdjustSalaryDialogComponent } from './pages/dialogs/adjust-salary-dialog/adjust-salary-dialog.component';
import { DateRangeDialogComponent } from './pages/dialogs/date-range-dialog/date-range-dialog.component';
import { PayslipListDialogComponent } from './pages/dialogs/payslip-list-dialog/payslip-list-dialog.component';
import { CompanyPayslipsDialogComponent } from './pages/dialogs/company-payslips-dialog/company-payslips-dialog.component';
import { ManagePermissionsDialogComponent } from './pages/dialogs/manage-permissions-dialog/manage-permissions-dialog.component';
import { ChangePasswordDialogComponent } from './pages/dialogs/change-password-dialog/change-password-dialog.component';
import { AddToContactsDialogComponent } from './pages/dialogs/add-to-contacts-dialog/add-to-contacts-dialog.component';
import { LinkBiometricToDialogComponent } from './pages/dialogs/link-biometric-to-dialog/link-biometric-to-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ImportAttendancesDialogComponent } from './pages/dialogs/import-attendances-dialog/import-attendances-dialog.component';
import { SearchEmployeeComponent } from './pages/search-employee/search-employee.component';
import { ViewAnnouncementDialogComponent } from './pages/dialogs/view-announcement-dialog/view-announcement-dialog.component';
import { ViewAttendanceDialogComponent } from './pages/dialogs/view-attendance-dialog/view-attendance-dialog.component';
import { ViewQuestionDialogComponent } from './pages/dialogs/view-question-dialog/view-question-dialog.component';
import { TakeExaminationComponent } from './pages/take-examination/take-examination.component';
import { CreateExaminationScheduleDialogComponent } from './pages/dialogs/create-examination-schedule-dialog/create-examination-schedule-dialog.component';
import { ViewExaminationResultsDialogComponent } from './pages/dialogs/view-examination-results-dialog/view-examination-results-dialog.component';
import { ViewLeaveApprovalsDialogComponent } from './pages/dialogs/view-leave-approvals-dialog/view-leave-approvals-dialog.component';
import { UploadProfilePictureDialogComponent } from './pages/dialogs/upload-profile-picture-dialog/upload-profile-picture-dialog.component';
import { PreviewImageDialogComponent } from './pages/dialogs/preview-image-dialog/preview-image-dialog.component';
import { ReviewExaminationResultDialogComponent } from './pages/dialogs/review-examination-result-dialog/review-examination-result-dialog.component';
import { ViewPayslipDialogComponent } from './pages/dialogs/view-payslip-dialog/view-payslip-dialog.component';
import { ResetPasswordDialogComponent } from './pages/dialogs/reset-password-dialog/reset-password-dialog.component';
import { AttendancesListDialogComponent } from './pages/dialogs/attendances-list-dialog/attendances-list-dialog.component';
import { WorkforceAttendancesListDialogComponent } from './pages/dialogs/workforce-attendances-list-dialog/workforce-attendances-list-dialog.component';
import { UnderMaintenanceComponent } from './pages/status/under-maintenance/under-maintenance.component';
import { LoanRecommendationDialogComponent } from './pages/dialogs/loan-recommendation-dialog/loan-recommendation-dialog.component';
import { LoanReleaseDialogComponent } from './pages/dialogs/loan-release-dialog/loan-release-dialog.component';
import { ManageLoanDocumentsDialogComponent } from './pages/dialogs/manage-loan-documents-dialog/manage-loan-documents-dialog.component';
import { EditSalaryAdjustmentDialogComponent } from './pages/dialogs/edit-salary-adjustment-dialog/edit-salary-adjustment-dialog.component';
import { NotFoundComponent } from './pages/status/not-found/not-found.component';
import { WorkforceLeavesListDialogComponent } from './pages/dialogs/workforce-leaves-list-dialog/workforce-leaves-list-dialog.component';
import { AdjustPayslipDialogComponent } from './pages/dialogs/adjust-payslip-dialog/adjust-payslip-dialog.component';
import { ExportAttendancesDialogComponent } from './pages/dialogs/export-attendances-dialog/export-attendances-dialog.component';
import { AngularDeviceInformationService } from 'angular-device-information';
import { AbnormalAttendancesDialogComponent } from './pages/dialogs/abnormal-attendances-dialog/abnormal-attendances-dialog.component';
import { AddNoteDialogComponent } from './pages/dialogs/add-note-dialog/add-note-dialog.component';
import { ViewEditNoteDialogComponent } from './pages/dialogs/view-edit-note-dialog/view-edit-note-dialog.component';
import { QuillModule } from 'ngx-quill';
import { EncodeCompanySalaryLoanDialogComponent } from './pages/dialogs/encode-company-salary-loan-dialog/encode-company-salary-loan-dialog.component';
import { EncodeSocialLoanDialogComponent } from './pages/dialogs/encode-social-loan-dialog/encode-social-loan-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    AddAttendanceDialogComponent,
    AdjustSalaryDialogComponent,
    DateRangeDialogComponent,
    PayslipListDialogComponent,
    CompanyPayslipsDialogComponent,
    ManagePermissionsDialogComponent,
    ChangePasswordDialogComponent,
    AddToContactsDialogComponent,
    LinkBiometricToDialogComponent,
    ImportAttendancesDialogComponent,
    SearchEmployeeComponent,
    ViewAnnouncementDialogComponent,
    ViewAttendanceDialogComponent,
    ViewQuestionDialogComponent,
    TakeExaminationComponent,
    CreateExaminationScheduleDialogComponent,
    ViewExaminationResultsDialogComponent,
    ViewLeaveApprovalsDialogComponent,
    UploadProfilePictureDialogComponent,
    PreviewImageDialogComponent,
    ReviewExaminationResultDialogComponent,
    ViewPayslipDialogComponent,
    ResetPasswordDialogComponent,
    AttendancesListDialogComponent,
    WorkforceAttendancesListDialogComponent,
    UnderMaintenanceComponent,
    LoanRecommendationDialogComponent,
    LoanReleaseDialogComponent,
    ManageLoanDocumentsDialogComponent,
    EditSalaryAdjustmentDialogComponent,
    NotFoundComponent,
    WorkforceLeavesListDialogComponent,
    AdjustPayslipDialogComponent,
    ExportAttendancesDialogComponent,
    AbnormalAttendancesDialogComponent,
    AddNoteDialogComponent,
    ViewEditNoteDialogComponent,
    EncodeCompanySalaryLoanDialogComponent,
    EncodeSocialLoanDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    QuillModule.forRoot(),
    ModalModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:15000'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AngularDeviceInformationService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
