<div class="d-flex align-items-center position-relative p-5 vh-100 vw-100 design-login">
  <div class="w-100">
    <div class="row justify-content-center">
      <div class="col-md-4">
        <div class="text-center mb-4">
          <img src="assets/svgs/logo.svg" class="site-logo">
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center justify-content-center justify-content-md-center mb-2">
              <div class="col-auto">
                <span class="fa-solid fa-triangle-exclamation fa-3x fa-fw text-yellow"></span>
              </div>
              <div class="col">
                <h1 class="m-0">Oops!</h1>
                <h3 class="text-warning lh-1 m-0">Not Found</h3>
              </div>
            </div>
            <p class="lh-1-25">The page you are trying to access doesn't or no longer exist.</p>
            <p class="lh-1-25 m-0">If you think there is a mistake, please contact the System Administrator.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
