<div class="p-4">
  <h5 class="text-capitalize m-0">Add Note</h5>
  <hr>
  <div class=" overflow-auto">
    <form [formGroup]="save_form">
      <div class="form-group">
        <label for="" class="mb-1">Note Title:</label>
        <input type="text" class="form-control form-control-lg" formControlName="NoteTitle">
      </div>
      <div class="form-group">
        <quill-editor
          formControlName="NoteContent"
          [modules]="utilityService.quillModules"
          theme="snow"
          [styles]="{ color: '#222222', height: '40vh' }"
        ></quill-editor>
      </div>
    </form>
  </div>
  <hr>
  <div class="d-flex justify-content-end text-end">
    <button type="button" class="btn btn-success" (click)="saveChanges()">
      <span class="fa-solid fa-save fa-fw"></span>
      <span class="ms-2">Save</span>
    </button>
  </div>
</div>
