import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-falling-hearts',
  templateUrl: './falling-hearts.component.html',
  styleUrls: ['./falling-hearts.component.scss']
})
export class FallingHeartsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }

  arrFiller(len: number) {
    return Array(len).fill('');
  }
}
