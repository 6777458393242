import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL, HTTP_OPTIONS } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class NoteService {
  constructor(
    private http: HttpClient
  ) { }

  getNoteList(id_number: string, search_for: string = null) {
    return this.http.get(`${API_URL}/note/list?idNumber=${id_number}${search_for != null ? '&searchFor=' + search_for : ''}`, HTTP_OPTIONS);
  }

  createNote(params: any) {
    return this.http.post(`${API_URL}/note/create`, params, HTTP_OPTIONS);
  }

  updateNote(note_id: any, params: any) {
    return this.http.put(`${API_URL}/note/${note_id}/update`, params, HTTP_OPTIONS);
  }

  deleteNote(note_id: any) {
    return this.http.delete(`${API_URL}/note/${note_id}/delete`, HTTP_OPTIONS);
  }
}
