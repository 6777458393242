import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './services/auth.service';
import { UtilityService } from './services/utility.service';

// tslint:disable-next-line: ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private swPush: SwPush,
    private swUpdate: SwUpdate,
    private authService: AuthService,
    private utilityService: UtilityService
  ) {
    let auth = this.authService.getUser();

    if(this.swUpdate.available) {
      this.swUpdate.available.subscribe(() => {
        this.utilityService.prompt({
          icon: 'success',
          title: 'A new version is available.',
          text: 'It is required that the system you use is using the latest version. New version will be applied on next page refresh.',
          showCancelButton: false
        }).then((result: any) => {
          this.spinner.show();

          window.location.reload();
        });
      });
    }

    this.utilityService.requestNotificationPermission(auth);

    if(this.swPush.messages && auth != null) {
      this.swPush.messages.subscribe();
    }

    this.router.events.subscribe((event: any) => {
      if(event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
        });
      }
    });
  }
}
