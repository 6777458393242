<div class="p-4">
  <h5 class="text-capitalize m-0">Change Password</h5>
  <hr class="mb-0">
  <div class="pt-4">
    <div class="alert alert-warning d-flex align-items-center text-white" *ngIf="is_forced">
      <span class="fa-solid fa-circle-exclamation fa-2x fa-fw"></span>
      <span class="ms-2">You are required to change your password immediately.</span>
    </div>
    <p>Note: After you have changed your password, you will be logged out of the system to ensure that your account is updated.</p>
    <form [formGroup]="save_form" (submit)="save()">
      <div class="form-group">
        <label for="" class="mb-1">Old Password:</label>
        <input type="password" class="form-control form-control-lg" formControlName="OldPassword" autofocus>
      </div>
      <div class="form-group">
        <label for="" class="mb-1">New Password:</label>
        <input type="password" class="form-control form-control-lg" formControlName="NewPassword">
      </div>
      <div class="form-group">
        <label for="" class="mb-1">Confirm New Password:</label>
        <input type="password" class="form-control form-control-lg" formControlName="ConfirmNewPassword">
      </div>
      <div class="text-end">
        <button type="submit" class="btn btn-success btn-lg">
          <span class="fa-solid fa-save fa-fw"></span>
          <span class="ms-2">Save</span>
        </button>
      </div>
    </form>
  </div>
</div>
