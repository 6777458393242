import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ChartsModule } from 'ng2-charts';
import { DragAndDropFilesDirective } from '../directives/drag-and-drop-files.directive';
import { PaginationComponent } from '../components/pagination/pagination.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AttendanceCalendarComponent } from '../components/attendance-calendar/attendance-calendar.component';
import { BlockedAccessComponent } from '../components/blocked-access/blocked-access.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgSelectModule } from '@ng-select/ng-select';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { LabelBoxComponent } from '../components/label-box/label-box.component';
import { SnowComponent } from '../components/snow/snow.component';
import { UiEffectsComponent } from '../components/ui-effects/ui-effects.component';
import { FallingHeartsComponent } from '../components/falling-hearts/falling-hearts.component';
import { MaintenanceModeComponent } from '../components/maintenance-mode/maintenance-mode.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxTypedJsModule } from 'ngx-typed-js';

@NgModule({
  declarations: [
    BlockedAccessComponent,
    PaginationComponent,
    SpinnerComponent,
    AttendanceCalendarComponent,
    LabelBoxComponent,
    UiEffectsComponent,
    SnowComponent,
    FallingHeartsComponent,
    MaintenanceModeComponent,

    DragAndDropFilesDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    PaginationModule,
    TooltipModule,
    ChartsModule,
    NgSelectModule,
    PopoverModule
  ],
  exports: [
    BlockedAccessComponent,
    PaginationComponent,
    SpinnerComponent,
    AttendanceCalendarComponent,
    LabelBoxComponent,
    UiEffectsComponent,
    MaintenanceModeComponent,

    DragAndDropFilesDirective,

    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    CollapseModule,
    PaginationModule,
    TooltipModule,
    ChartsModule,
    NgSelectModule,
    QRCodeModule,
    ImageCropperModule,
    CarouselModule,
    TabsModule,
    NgxTypedJsModule
  ]
})
export class SharedModule { }
