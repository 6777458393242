import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-ui-effects',
  templateUrl: './ui-effects.component.html',
  styleUrls: ['./ui-effects.component.scss']
})
export class UiEffectsComponent implements OnInit {
  auth: any = null;

  should_snow: boolean = false;
  should_fall_hearts: boolean = false;

  constructor(
    private authService: AuthService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.auth = this.authService.getUser();

    let stamp = this.utilityService.parseStamp(new Date());

    if(this.auth != null) {
      stamp = this.utilityService.parseStamp(new Date());
    }

    if(stamp.isBetween(`${stamp.format('YYYY')}-12-01`, `${stamp.format('YYYY')}-12-31`)) {
      this.should_snow = true;
    }

    if(stamp.isSame(`${stamp.format('YYYY')}-02-14`)) {
      this.should_fall_hearts = true;
    }
  }
}
