import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {
  auth: any = null;
  is_forced: boolean = false;

  save_form: FormGroup = new FormGroup({
    Idnumber: new FormControl(null, Validators.required),
    OldPassword: new FormControl(null, Validators.required),
    NewPassword: new FormControl(null, Validators.required),
    ConfirmNewPassword: new FormControl(null, Validators.required)
  });

  constructor(
    public modal: BsModalRef,
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.auth = this.authService.getUser();

    this.save_form.patchValue({
      Idnumber: this.auth.id_number
    });
  }

  save() {
    if(this.save_form.invalid) {
      this.utilityService.toast({
        icon: 'warning',
        title: 'Please fill up all required fields.'
      });

      return;
    }

    this.spinner.show();

    this.authService.changePassword(this.save_form.getRawValue()).subscribe((result: any) => {
      this.spinner.hide();

      this.utilityService.toast({
        icon: 'success',
        title: 'Password has been changed.'
      });

      this.modal.hide();

      this.authService.logout();

      this.router.navigate(['/auth/login']);
    }, (error) => {
      this.spinner.hide();

      this.utilityService.toast({
        icon: 'error',
        title: error.error
      });
    });
  }
}
