import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-maintenance-mode',
  templateUrl: './maintenance-mode.component.html',
  styleUrls: ['./maintenance-mode.component.scss']
})
export class MaintenanceModeComponent implements OnInit, OnDestroy {
  is_maintenance_mode: boolean = false;
  maintenance_mode_interval: any = null;

  constructor(
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    this.checkIfMaintenanceMode();

    this.maintenance_mode_interval = setInterval(() => {
      this.checkIfMaintenanceMode();
    }, 1000 * 60 * 15);
  }

  ngOnDestroy(): void {
    if(this.maintenance_mode_interval != null) {
      clearInterval(this.maintenance_mode_interval);

      this.maintenance_mode_interval = null;
    }
  }

  checkIfMaintenanceMode() {
    this.utilityService.getSettings('MaintenanceMode').subscribe((result: any) => {
      if(result != null && result.Bit != null) {
        this.is_maintenance_mode = result.Bit;
      }
    }, (error: any) => {
      this.is_maintenance_mode = false;
    });
  }
}
