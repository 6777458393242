import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { NoteService } from 'src/app/services/note.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-view-edit-note-dialog',
  templateUrl: './view-edit-note-dialog.component.html',
  styleUrls: ['./view-edit-note-dialog.component.scss']
})
export class ViewEditNoteDialogComponent implements OnInit {
  note: any = null;
  is_edit_mode$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  save_form: FormGroup = new FormGroup({
    NoteTitle: new FormControl(null),
    NoteContent: new FormControl(null, Validators.required)
  });

  constructor(
    public modal: BsModalRef,
    public utilityService: UtilityService,
    private spinner: NgxSpinnerService,
    private noteService: NoteService
  ) { }

  ngOnInit(): void { }

  toggleEditMode() {
    if(this.is_edit_mode$.getValue()) {
      this.is_edit_mode$.next(false);
    } else {
      this.is_edit_mode$.next(true);

      if(this.note != null) {
        this.save_form.patchValue({
          NoteTitle: this.note.NoteTitle,
          NoteContent: this.note.NoteContent
        });
      }
    }
  }

  saveChanges() {
    if(this.save_form.invalid) {
      this.utilityService.toast({
        icon: 'warning',
        title: 'Please fill up all required fields.'
      });

      return;
    }

    this.spinner.show();

    this.noteService.updateNote(this.note.Id, this.save_form.getRawValue()).subscribe((result: any) => {
      this.spinner.hide();

      this.utilityService.toast({
        icon: 'success',
        title: 'Note has been updated.'
      });

      this.note.NoteTitle = this.save_form.get('NoteTitle').value;
      this.note.NoteContent = this.save_form.get('NoteContent').value;

      this.toggleEditMode();
    }, (error: any) => {
      this.spinner.hide();

      this.utilityService.toast({
        icon: 'error',
        title: 'Failed to update note.'
      });
    });
  }

  deleteNote(note: any) {
    let note_title = !!note.NoteTitle ? note.NoteTitle : 'Untitled Note';

    this.utilityService.prompt({
      icon: 'warning',
      title: `Are you sure you want to delete your note "${note_title}"?`,
      text: 'This cannot be undone later.',
      showCancelButton: true,
      confirmButtonText: 'Yes'
    }).then((result: any) => {
      if(result.isConfirmed) {
        this.spinner.show();

        this.noteService.deleteNote(note.Id).subscribe((result: any) => {
          this.spinner.hide();

          this.utilityService.toast({
            icon: 'success',
            title: 'Note has been deleted.'
          });

          this.modal.hide();
        }, (error: any) => {
          this.spinner.hide();

          this.utilityService.toast({
            icon: 'error',
            title: 'Failed to delete note.'
          });
        });
      }
    });
  }
}
