import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InAppNotificationHubService {
  in_app_notification_connection: signalR.HubConnection;

  constructor() { }

  setupHubConnection() {
    this.in_app_notification_connection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.socket_url}/in-app-notification`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      }).build();
  }

  startHubConnection(callback: Function = null) {
    this.setupHubConnection();

    let connection = this.in_app_notification_connection.start();

    if(callback != null) {
      connection.then(() => callback(this));
    }
  }

  /*
  * Invokers
  */
  getNotificationList(timezone: any, id_number: any) {
    return this.in_app_notification_connection.invoke('GetNotificationList', timezone, id_number);
  }

  markAsRead(timezone: any, id_number: any, notification_id: number) {
    return this.in_app_notification_connection.invoke('MarkAsRead', timezone, id_number, notification_id);
  }

  markAsUnread(timezone: any, id_number: any, notification_id: number) {
    return this.in_app_notification_connection.invoke('MarkAsUnread', timezone, id_number, notification_id);
  }

  /*
  * Listeners
  */
  listenToNotificationList(callback: Function = null) {
    this.in_app_notification_connection.on('GetNotificationList', (result: any) => {
      if(callback != null) {
        callback(result);
      }
    });
  }
}
