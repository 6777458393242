import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { PageNavigationService } from 'src/app/services/page-navigation.service';
import { UtilityService } from 'src/app/services/utility.service';
import { InAppNotificationHubService } from 'src/app/sockets/in-app-notification-hub.service';
import { ChangePasswordDialogComponent } from '../dialogs/change-password-dialog/change-password-dialog.component';
import { NoteService } from 'src/app/services/note.service';
import { AddNoteDialogComponent } from '../dialogs/add-note-dialog/add-note-dialog.component';
import { ViewEditNoteDialogComponent } from '../dialogs/view-edit-note-dialog/view-edit-note-dialog.component';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss'],
})
export class ManagementComponent implements OnInit, OnDestroy {
  auth: any = null;
  is_change_password_dialog_open: boolean = false;
  change_password_dialog: any = null;
  profile_picture: any = null;
  in_app_notification_socket: any = null;
  in_app_notification_interval: any = null;

  notes: any = [];

  router_event_subscriber: any = null;

  module_title = new BehaviorSubject<string>('');
  module_breadcrumbs$ = new BehaviorSubject<any>([]);
  website_version$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  api_version$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  is_loading_notes$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  in_app_notifications$: BehaviorSubject<any> = new BehaviorSubject([]);
  unread_in_app_notifications_count$: BehaviorSubject<number> = new BehaviorSubject(0);

  credentials_validation_interval: any = null;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public authService: AuthService,
    public pageNavigationService: PageNavigationService,
    public utilityService: UtilityService,
    private modal: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private inAppNotificationHub: InAppNotificationHubService,
    private noteService: NoteService
  ) {
    this.auth = this.authService.getUser();

    this.website_version$.next(`v${this.utilityService.websiteVersion()}`);

    this.utilityService.getApiVersion().subscribe((result: any) => {
      this.api_version$.next(`v${result}`);
    });

    this.router_event_subscriber = this.router.events.subscribe((e) => {
      if(e instanceof NavigationEnd) {
        this.setModuleVariables(e.urlAfterRedirects);

        if(this.authService.getProfilePicture() != null) {
          this.authService.getProfilePicture().subscribe((result: any) => {
            this.profile_picture = result;
          });
        }

        if(this.authService.shouldChangePassword()) {
          this.changePassword(true);
        }
      }
    });

    this.route
      .url.subscribe(() => {
        if(this.route.snapshot.firstChild.data.title == null) {
          this.module_title.next(this.route.snapshot.firstChild.firstChild.data.title);
        } else {
          this.module_title.next(this.route.snapshot.firstChild.data.title);
        }
      });
  }

  ngOnInit(): void {
    this.runCredentialsValidation();

    if(this.credentials_validation_interval == null) {
      this.credentials_validation_interval = setInterval(() => {
        this.runCredentialsValidation();
      }, 1000 * 60 * 5);
    }

    this.initializeHub(() => {
      this.loadNotes();
      this.loadNotifications();
    });
  }

  ngOnDestroy(): void {
    if(this.credentials_validation_interval != null) {
      clearInterval(this.credentials_validation_interval);
    }

    if(this.router_event_subscriber != null) {
      this.router_event_subscriber.unsubscribe();
    }

    if(this.in_app_notification_interval != null) {
      clearInterval(this.in_app_notification_interval);
    }

    this.in_app_notification_interval = null;
    this.credentials_validation_interval = null;
  }

  runCredentialsValidation() {
    if(this.authService.isLoggedIn()) {
      console.log('Validating credentials...');

      this.authService.validateCredentials().subscribe((result: any) => {
        console.log(`Validation Result: ${result ? 'Valid' : 'Invalid'}`);

        if(!result) {
          this.authService.logout();

          this.router.navigate(['/auth/login']);
        }
      });
    }
  }

  loadNotes() {
    this.is_loading_notes$.next(true);

    this.noteService.getNoteList(this.auth.id_number).subscribe((result: any) => {
      this.is_loading_notes$.next(false);

      this.notes = result.map((item: any) => {
        if(item.DateCreated != null) {
          item.FormattedDateCreatedAgo = this.utilityService.parseStamp(item.DateCreated).fromNow();
          item.FormattedDateCreated = this.utilityService.parseStamp(item.DateCreated).format('MMM DD YYYY hh:mm A');
        }

        if(item.DateUpdated != null) {
          item.FormattedDateUpdatedAgo = this.utilityService.parseStamp(item.DateUpdated).fromNow();
          item.FormattedDateUpdated = this.utilityService.parseStamp(item.DateUpdated).format('MMM DD YYYY hh:mm A');
        }

        return item;
      });
    }, (error: any) => {
      this.is_loading_notes$.next(false);

      this.notes = [];
    });
  }

  viewEditNote(note: any) {
    let modal = this.modal.show(ViewEditNoteDialogComponent, {
      initialState: {
        note: note
      }
    });

    modal.onHidden.subscribe(() => {
      this.loadNotes();
    });
  }

  addNote() {
    let modal = this.modal.show(AddNoteDialogComponent);

    modal.onHidden.subscribe(() => {
      this.loadNotes();
    });
  }

  initializeHub(callback: Function = null) {
    try {
      this.inAppNotificationHub.startHubConnection((socket: any) => {
        this.in_app_notification_socket = socket;

        this.in_app_notification_socket.listenToNotificationList((result: any) => {
          let temp = result.map((item: any) => {
              item.FormattedMessage = item.Message
                .replaceAll('::ACTOR::', `<strong>${item.ActorVariable}</strong>`)
                .replaceAll('::RECEIVER::', `<strong>${item.ReceiverVariable}</strong>`);

              if(item.DateCreated != null) {
                item.FormattedDateCreatedAgo = this.utilityService.parseStamp(item.DateCreated).fromNow();
                item.FormattedDateCreated = this.utilityService.parseStamp(item.DateCreated).format('MMM DD YYYY hh:mm A');
              }

              return item;
            });

          if(result.length > 0) {
            this.in_app_notifications$.next(temp);
            this.unread_in_app_notifications_count$.next(temp.filter((item: any) => !item.IsRead).length);
          } else {
            this.in_app_notifications$.next([]);
            this.unread_in_app_notifications_count$.next(0);
          }
        });

        if(callback != null) {
          callback();
        }
      });
    } catch(ex) { }
  }

  loadNotifications() {
    this.in_app_notifications$.next(null);
    this.unread_in_app_notifications_count$.next(0);

    this.in_app_notification_socket.getNotificationList(this.auth.timezone, this.auth.id_number);

    this.in_app_notification_interval = setInterval(() => {
      this.in_app_notification_socket.getNotificationList(this.auth.timezone, this.auth.id_number);
    }, 10000);
  }

  onAreaClick(event) {
    let class_name = 'g-sidenav-pinned';

    if(this.document.body.classList.contains(class_name) && event.target.closest('.sidenav') == null) {
      this.document.body.classList.remove(class_name);
    }
  }

  setModuleVariables(url) {
    this.module_breadcrumbs$.next(url.split('?')[0].substring(1).split('/').slice(0, 2).map((item: any) => {
      let texts = item.split('-');

      texts = texts.map((text: any) => {
        return text.substring(0, 1).toUpperCase() + text.substring(1).toLowerCase();
      });

      return texts.join(' ');
    }));
  }

  toggleSidenav(event) {
    event.stopPropagation();

    let class_name = 'g-sidenav-pinned';

    if(this.document.body.classList.contains(class_name)) {
      this.document.body.classList.remove(class_name);
    } else {
      this.document.body.classList.add(class_name);
    }
  }

  changePassword(is_forced: boolean = false) {
    if(!this.is_change_password_dialog_open && this.auth != null) {
      this.is_change_password_dialog_open = true;

      this.change_password_dialog = this.modal.show(ChangePasswordDialogComponent, {
        backdrop: is_forced ? 'static' : true,
        initialState: {
          is_forced: is_forced
        }
      });

      this.change_password_dialog.onHidden.subscribe(() => {
        this.is_change_password_dialog_open = false;
      });
    }
  }

  logout() {
    this.authService.logout();

    this.router.navigate(['/auth/login']);
  }

  markNotificationAsRead(event: any, notification_id: number) {
    event.stopPropagation();

    try {
      this.in_app_notification_socket.markAsRead(this.auth.timezone, this.auth.id_number, notification_id);
    } catch(ex) { }

    return false;
  }

  markNotificationAsUnread(event: any, notification_id: number) {
    event.stopPropagation();

    try {
      this.in_app_notification_socket.markAsUnread(this.auth.timezone, this.auth.id_number, notification_id);
    } catch(ex) { }

    return false;
  }
}
