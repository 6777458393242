import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL, HTTP_OPTIONS } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class ExaminationService {
  constructor(
    private http: HttpClient
  ) { }

  getExaminationList(company_id: any, date_started: any = null, date_until: any = null) {
    let params = '';

    if(date_started != null) {
      params += `?dateStarted=${date_started}`;
    }

    if(date_until != null) {
      params += `${date_started != null ? '&' : '?'}dateUntil=${date_until}`;
    }

    return this.http.get(`${API_URL}/examination/${company_id}/list${params}`, HTTP_OPTIONS);
  }

  getGroupedExaminationList(company_id: any) {
    return this.http.get(`${API_URL}/examination/${company_id}/grouped/list`, HTTP_OPTIONS);
  }

  getGroupedExaminationListByDepartment(company_id: any, date_started: any = null, date_until: any = null) {
    let params = '';

    if(date_started != null) {
      params += `?dateStarted=${date_started}`;
    }

    if(date_until != null) {
      params += `${date_started != null ? '&' : '?'}dateUntil=${date_until}`;
    }

    return this.http.get(`${API_URL}/examination/${company_id}/grouped/by-department/list${params}`, HTTP_OPTIONS);
  }

  getExamination(id_number: any, stamp: any = null) {
    return this.http.get(`${API_URL}/examination/${id_number}/get${stamp != null ? '?date=' + stamp : ''}`, HTTP_OPTIONS);
  }

  getExaminationById(examination_id: any) {
    return this.http.get(`${API_URL}/examination/${examination_id}/get/by-id`, HTTP_OPTIONS);
  }

  getQuestionList(company_id: any) {
    return this.http.get(`${API_URL}/examination/${company_id}/question/list`, HTTP_OPTIONS);
  }

  getQuestion(question_id: any) {
    return this.http.get(`${API_URL}/examination/question/${question_id}/get`, HTTP_OPTIONS);
  }

  getExaminationQuestion(examination_id: any) {
    return this.http.get(`${API_URL}/examination/question/exam/${examination_id}/list`, HTTP_OPTIONS);
  }

  createExaminationSchedule(params: any) {
    return this.http.post(`${API_URL}/examination/schedule/create`, params, HTTP_OPTIONS);
  }

  startExamination(examination_id: any) {
    return this.http.post(`${API_URL}/examination/${examination_id}/start`, {}, HTTP_OPTIONS);
  }

  processExamination(params: any) {
    return this.http.post(`${API_URL}/examination/process`, params, HTTP_OPTIONS);
  }

  createQuestion(params: any) {
    let http_options = HTTP_OPTIONS;
    let formData = new FormData();

    http_options.headers = http_options.headers.delete('Content-Type');

    formData.append('CompanyId', params.CompanyId);
    formData.append('Type', params.Type);
    formData.append('Body', params.Body);
    formData.append('Image', params.Image);
    formData.append('EnumerationCount', params.EnumerationCount != null ? params.EnumerationCount : 0);

    if(params.QuestionChoices.length > 0) {
      params.QuestionChoices.forEach((item: any, index: any) => {
        formData.append('ChoiceIndex', index + 1);
        formData.append(`ChoiceBody${index + 1}`, item.Body != null ? item.Body : '');
        formData.append(`ChoiceImage${index + 1}`, item.Image != null ? item.Image : '');
        formData.append(`ChoiceIsCorrect${index + 1}`, item.IsCorrect);
      });
    }

    if(params.QuestionGroups.length > 0) {
      params.QuestionGroups.forEach((item: any) => {
        formData.append('DepartmentId', item.DepartmentId);
      });
    }

    return this.http.post(`${API_URL}/examination/question/create`, formData, http_options);
  }

  revalidateExaminationAnswer(examination_answer_id: any, is_correct: boolean) {
    return this.http.put(`${API_URL}/examination/${examination_answer_id}/re-validate/as/${is_correct ? 'correct' : 'wrong'}`, {}, HTTP_OPTIONS);
  }

  resetExamination(examination_id: any) {
    return this.http.put(`${API_URL}/examination/${examination_id}/reset`, {}, HTTP_OPTIONS);
  }

  softDeleteQuestion(question_id: number) {
    return this.http.delete(`${API_URL}/examination/question/${question_id}/soft-delete`, HTTP_OPTIONS);
  }
}
