import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService, TOKEN_KEY } from '../services/auth.service';
import { catchError, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from '../services/utility.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  whitelist: string[] = [
    'https://api.ipify.org?format=json'
  ];

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private utilityService: UtilityService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(TOKEN_KEY);

    if(!(this.whitelist.indexOf(request.url) >= 0)
      && token != null
      && this.authService.getUser() != null
    ) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError((error: any) => {
        if(error instanceof HttpErrorResponse && error.status === 401) {
          this.authService.logout();

          this.router.navigate(['/'], {
            replaceUrl: true
          });

          this.utilityService.toast({
            icon: 'error',
            title: 'Your previous login credential is invalid and/or has expired.'
          });

          this.spinner.hide();
        }

        return throwError(error);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    let user: any = this.authService.getUser();

    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`,
        'TimeZone': user.timezone
      }
    });
  }
}
