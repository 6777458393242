<div class="min-height-300 bg-success position-fixed w-100"></div>
<div class="d-flex flex-column vh-100 vw-100">
  <nav class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" data-scroll="false" *ngIf="auth != null">
    <div class="container-fluid py-1 px-0 px-xl-3 mt-3">
      <a class="navbar-brand" href="#" target="_blank">
        <img src="/assets/svgs/logo.svg" class="navbar-brand-img" style="height: 3rem;">
      </a>
    </div>
  </nav>
  <div class="flex-fill p-3">
    <div class="d-flex align-items-start justify-content-center h-100 w-100">
      <div class="row justify-content-center w-100">
        <div class="col-md-4">
          <div class="card mb-3">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <div class="icon icon-shape icon-sm bg-gradient-success me-2 d-flex align-items-center justify-content-center shadow-danger text-center rounded-circle">
                  <small class="fw-bold text-translucent">{{ (auth.first_name.substring(0, 1) + auth.last_name.substring(0, 1)).toUpperCase() }}</small>
                </div>
                <div class="flex-fill blockify">
                  <div class="blockify-title">{{ auth.first_name + ' ' + auth.last_name }}</div>
                  <div class="blockify-subtitle">{{ auth.job_name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="card max-vh-50 overflow-auto">
            <div class="card-body">
              <ng-container *ngIf="examination != null; else alterContent;">
                <ng-container *ngIf="!is_started">
                  <div class="display-title">Examination</div>
                  <!-- <p>This examination is conducted to ensure that your understanding of the company' workflow is in-line with it's actual operation.</p> -->
                  <div class="text-dark">
                    <p>This examination consists of <strong>{{ questions.length }} question{{ questions.length == 1 ? '' : 's' }}</strong>. You have <strong>{{ original_remaining_time[0] }} or {{ original_remaining_time[1] }} seconds</strong> for each questions to answer.</p>
                    <p>Good Luck! May the odds be ever in your favor.</p>
                  </div>
                  <div class="alert alert-warning d-flex align-items-center text-white" *ngIf="is_forced">
                    <span class="fa-solid fa-circle-exclamation fa-2x fa-fw"></span>
                    <span class="ms-2">Do not refresh or close this page once the examination has started. Refreshing or closing the page, whether it be intentional or unintentional, will result to your examination being forfeited.</span>
                  </div>
                  <div class="row justify-content-between">
                    <div class="col-sm mb-3 mb-sm-0">
                      <a [routerLink]="['/management/dashboard']" class="btn btn-secondary h-100 w-100">
                        <span class="fa-solid fa-chevron-left fa-fw"></span>
                        <span class="ms-2">Go Back to Dashboard</span>
                      </a>
                    </div>
                    <div class="col-sm">
                      <button type="button" class="btn btn-success h-100 w-100" (click)="startExamination()">
                        <span class="fa-solid fa-hourglass-start fa-fw"></span>
                        <span class="ms-2">Start Examination</span>
                      </button>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="is_started && !is_finished">
                  <div class="text-end mb-3">
                    <small>Remaining Time: <strong class="text-danger">{{ remaining_time }}s</strong></small>
                  </div>
                  <ng-container *ngFor="let question of questions; let i = index">
                    <ng-container *ngIf="display_index == i">
                      <div class="group-box mb-3">
                        <div class="group-box-title">Question {{ i + 1 }}</div>
                        <div class="px-5">
                          <img [src]="utilityService.getApiUrl() + '/Storage/Images/Questions/' + question.Image" class="w-100" *ngIf="question.Image != null">
                        </div>
                        <div class="text-dark" *ngIf="question.Body != null">{{ question.Body }}</div>
                      </div>
                      <ng-container *ngIf="question.Type == 'SHORT_ANSWER' || question.Type == 'ENUMERATION'">
                        <div class="form-group">
                          <label for="" class="mb-1">Answer:</label>
                          <input #answerField type="text" class="form-control form-control-lg" [(ngModel)]="short_answer" (keyup.enter)="setAnswer(short_answer, null); short_answer = ''">
                          <div class="text-end">
                            <small class="form-text text-muted">Press <kbd>Enter</kbd> to submit.</small>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="question.Type == 'MULTIPLE_CHOICE' || question.Type == 'TRUE_FALSE'">
                        <div class="row">
                          <div class="col-sm-6 mb-3" *ngFor="let choice of question.QuestionChoices">
                            <button type="button" class="btn btn-success h-100 w-100" (click)="setAnswer(choice.Body, choice.Image)">
                              <div class="px-5">
                                <img [src]="utilityService.getApiUrl() + '/Storage/Images/Answers/' + choice.Image" class="w-100" *ngIf="choice.Image != null">
                              </div>
                              <div *ngIf="choice.Body != null">{{ choice.Body }}</div>
                            </button>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="is_finished">
                  <div class="text-center text-dark" *ngIf="is_processing">
                    <span class="fa-solid fa-spinner fa-pulse fa-3x fa-fw"></span>
                    <h5 class="mt-2 mb-0">Processing data...</h5>
                  </div>
                  <div class="text-dark" *ngIf="!is_processing">
                    <div class="display-title">Result</div>
                    <div class="text-center mb-3">
                      <small>YOU GOT</small>
                      <h1 class="text-success m-0">{{ exam_result.Percentage }}%</h1>
                    </div>
                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <td>
                            <small>No. of Correct Answers:</small>
                            <div class="fw-bold">{{ exam_result.NoOfCorrectAnswers || 0 }}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <small>No. of Collectable Points:</small>
                            <div class="fw-bold">{{ exam_result.NoOfCollectablePoints }}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <small>No. of Questions:</small>
                            <div class="fw-bold">{{ exam_result.NoOfQuestions }}</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-center">
                      <a [routerLink]="['/management/dashboard']" class="btn btn-primary btn-sm">
                        <span class="fa-solid fa-chevron-left fa-fw"></span>
                        <span class="ms-2">Go Back to Dashboard</span>
                      </a>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <ng-template #alterContent>
                <div class="text-center text-dark">
                  <p>No examination available for you at the moment.</p>
                  <a [routerLink]="['/management/dashboard']" class="btn btn-primary btn-sm">
                    <span class="fa-solid fa-chevron-left fa-fw"></span>
                    <span class="ms-2">Go Back to Dashboard</span>
                  </a>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
