import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { NoteService } from 'src/app/services/note.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-add-note-dialog',
  templateUrl: './add-note-dialog.component.html',
  styleUrls: ['./add-note-dialog.component.scss']
})
export class AddNoteDialogComponent implements OnInit {
  auth: any = null;
  note: any = null;
  is_edit_mode$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  save_form: FormGroup = new FormGroup({
    NoteTitle: new FormControl(null),
    NoteContent: new FormControl(null, Validators.required),
    EmployeeIdNumber: new FormControl(null, Validators.required)
  });

  constructor(
    public modal: BsModalRef,
    public utilityService: UtilityService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private noteService: NoteService
  ) {
    this.auth = this.authService.getUser();
  }

  ngOnInit(): void {
    this.save_form.patchValue({
      EmployeeIdNumber: this.auth.id_number
    })
  }

  saveChanges() {
    if(this.save_form.invalid) {
      this.utilityService.toast({
        icon: 'warning',
        title: 'Please fill up all required fields.'
      });

      return;
    }

    this.spinner.show();

    this.noteService.createNote(this.save_form.getRawValue()).subscribe((result: any) => {
      this.spinner.hide();

      this.utilityService.toast({
        icon: 'success',
        title: 'Note has been added.'
      });

      this.modal.hide();
    }, (error: any) => {
      this.spinner.hide();

      this.utilityService.toast({
        icon: 'error',
        title: 'Failed to add note.'
      });
    });
  }
}
