<div class="d-flex vh-100 vw-100 py-5 design-login overflow-auto">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="px-3 px-md-5 mb-4">
          <img src="assets/svgs/logo.svg" style="height: 5rem;">
        </div>
        <div class="card overflow-hidden mb-2">
          <div class="card-body p-0">
            <div class="display-title text-center p-4 mb-0">Employee Details</div>
            <ng-container *ngIf="employee == null">
              <div class="pt-0 pb-4 px-4">
                <div class="box text-center">
                  <em class="text-muted">No results found.</em>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="employee != null">
              <div class="row justify-content-center" *ngIf="employee.ProfilePicture != null">
                <div class="col-6 col-sm-3">
                  <img [src]="utilityService.getApiUrl() + '/Storage/Images/Employees/' + employee.ProfilePicture" class="img-thumbnail" (error)="employee.ProfilePicture = null">
                </div>
              </div>
              <div class="detailer">
                <div class="detailer-label">ID NUMBER:</div>
                <div class="detailer-text">{{ employee.Idnumber }}</div>
              </div>
              <div class="detailer">
                <div class="detailer-label">FULL NAME:</div>
                <div class="detailer-text">{{ employee.FirstName + ' ' + (employee.MiddleName != null ? employee.MiddleName + ' ' : '') + employee.LastName }}</div>
              </div>
              <div class="detailer" *ngIf="employee.BirthDate != null">
                <div class="detailer-label">BIRTHDAY:</div>
                <div class="detailer-text">{{ employee.BirthDate }}</div>
              </div>
              <div class="detailer" *ngIf="employee.ContactNumber != null && employee.ContactNumber.trim() != ''">
                <div class="detailer-label">CONTACT NUMBER:</div>
                <div class="detailer-text">+63{{ employee.ContactNumber }}</div>
              </div>
              <div class="detailer" *ngIf="employee.IsCurrentlyEmployed">
                <div class="detailer-label">JOB TITLE:</div>
                <div class="detailer-text">{{ employee.JobDescription }}</div>
              </div>
              <div class="detailer">
                <div class="detailer-label">EMPLOYMENT STATUS:</div>
                <ng-container *ngIf="employee.IsCurrentlyEmployed">
                  <div class="detailer-text">{{ employee.EmploymentStatus.substring(0, 1).toUpperCase() + employee.EmploymentStatus.substring(1).toLowerCase() }} Employee</div>
                  <div class="detailer-subtext" *ngIf="employee.LastDayOfEmployment != null">Employed until {{ employee.FormattedLastDayOfEmployment }}</div>
                  <div class="etch-box bg-gradient-success mt-2">
                    <div class="etch-box-icon bg-white">
                      <span class="fa-solid fa-check fa-lg fa-fw text-success"></span>
                    </div>
                    <div class="etch-box-label text-white ms-2">Currently Employed</div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!employee.IsCurrentlyEmployed">
                  <div class="etch-box bg-gradient-danger">
                    <div class="etch-box-icon bg-white">
                      <span class="fa-solid fa-times fa-lg fa-fw text-danger"></span>
                    </div>
                    <div class="etch-box-label text-white ms-2">Resigned</div>
                  </div>
                </ng-container>
              </div>
              <div class="detailer">
                <div class="detailer-label">{{ employee.IsCurrentlyEmployed ? 'CURRENTLY' : 'PREVIOUSLY' }} EMPLOYED BY:</div>
                <div class="detailer-text">{{ employee.CompanyName }}</div>
                <div class="detailer-subtext" *ngIf="employee.CompanyAddress != null">{{ employee.CompanyAddress }}</div>
              </div>
              <div class="detailer" *ngIf="employee.IsCurrentlyEmployed && employee.DateOfEmployment != null">
                <div class="detailer-label">DATE EMPLOYED:</div>
                <div class="detailer-text">{{ employee.DateOfEmployment }}</div>
              </div>
              <div class="detailer" *ngIf="employee.Tin != null">
                <div class="detailer-label">TIN:</div>
                <div class="detailer-text">{{ employee.Tin }}</div>
              </div>
              <div class="detailer" *ngIf="employee.Sssnumber != null">
                <div class="detailer-label">SSS NUMBER:</div>
                <div class="detailer-text">{{ employee.Sssnumber }}</div>
              </div>
              <div class="detailer" *ngIf="employee.PhilhealthNumber != null">
                <div class="detailer-label">PHILHEALTH NUMBER:</div>
                <div class="detailer-text">{{ employee.PhilhealthNumber }}</div>
              </div>
              <div class="detailer" *ngIf="employee.PagibigNumber != null">
                <div class="detailer-label">PAG-IBIG NUMBER:</div>
                <div class="detailer-text">{{ employee.PagibigNumber }}</div>
              </div>
              <div class="detailer" *ngIf="employee.EmergencyContactPerson != null">
                <div class="detailer-label">IN CASE OF EMERGENCY, PLEASE CONTACT:</div>
                <div class="detailer-text">{{ employee.EmergencyContactPerson }}</div>
                <div class="detailer-subtext" *ngIf="employee.EmergencyContactNumber != null">+63{{ employee.EmergencyContactNumber }}</div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="text-center lh-1-25 mb-5">
          <small>All information on this website is published in good faith and for general information purpose only.</small>
        </div>
      </div>
    </div>
  </div>
</div>
