import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.scss']
})
export class UnderMaintenanceComponent implements OnInit, OnDestroy {
  maintenance_mode_interval: any = null;

  constructor(
    private router: Router,
    private authService: AuthService,
    private utilityService: UtilityService
  ) { }

  ngOnInit(): void {
    if(this.authService.isLoggedIn()) {
      this.authService.logout();
    }

    this.maintenance_mode_interval = setInterval(() => {
      this.utilityService.getSettings('MaintenanceMode').subscribe((result: any) => {
        if(result.Bit == null || result.Bit == false) {
          this.router.navigate(['/']);
        }
      });
    }, 5000);
  }

  ngOnDestroy(): void {
    clearInterval(this.maintenance_mode_interval);

    this.maintenance_mode_interval = null;
  }
}
