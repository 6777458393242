<div class="min-height-300 bg-success position-fixed w-100"></div>
<div (click)="onAreaClick($event)">
  <aside class="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4" id="sidenav-main" *ngIf="auth != null">
    <div class="d-flex flex-column h-100 w-100">
      <div class="sidenav-header">
        <i class="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none" (click)="toggleSidenav()"></i>
        <a class="navbar-brand m-0" href="#" target="_blank">
          <img src="/assets/svgs/logo.svg" class="navbar-brand-img w-100" alt="main_logo">
        </a>
      </div>
      <hr class="horizontal dark mt-0 d-none d-sm-block">
      <div class="d-block d-sm-none text-end pb-2 px-2">
        <button type="button" class="btn btn-white w-100" (click)="toggleSidenav($event)">
          <span class="fa-solid fa-arrow-left fa-lg fa-fw"></span>
          <span class="ms-1">Close Sidebar</span>
        </button>
      </div>
      <div class="collapse navbar-collapse w-auto flex-fill" id="sidenav-collapse-main">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/dashboard']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-gauge fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Dashboard</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/clock-in-out']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-clock fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Clock In / Out</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/messages']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-message fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Messages</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('ANNOUNCEMENTS::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/announcements']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-bullhorn fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Announcements</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('PAYCHECKS::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/paychecks']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-money-check-dollar fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Paychecks</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('LOAN::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/loans']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-hand-holding-dollar fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Loans</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('FUNDREQUEST::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/fund-requests']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-money-check fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Fund Requests</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('LEAVES::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/leaves']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-person-through-window fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Leaves</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('EMPMAN::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/employee-management']" [queryParams]="{ page: pageNavigationService.getPage('employee-management'), search: pageNavigationService.getSearch('employee-management') }">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-users fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Employee Management</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('TEAMS::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/teams']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-layer-group fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Teams</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('EXAMINATIONS::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/examinations']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-highlighter fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Examinations</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('ATTENDANCES::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/attendances']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-calendar-check fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Attendances</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('HELPDESK::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/helpdesk']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-handshake-angle fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Helpdesk</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('WORKFORCE::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/workforce-management']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-user-tie fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Workforce Management</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('DESKTOPMONITORING::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/desktop-monitoring']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-desktop fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Desktop Monitoring</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="authService.isAllowed('TOOLS::CAN_ACCESS')">
            <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/tools']">
              <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                <span class="fa-solid fa-screwdriver-wrench fa-lg fa-fw"></span>
              </div>
              <span class="nav-link-text ms-1">Tools</span>
            </a>
          </li>
          <li class="nav-item mt-3" *ngIf="authService.isAllowed('REFERENCES::CAN_MANAGE')">
            <h6 class="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">References</h6>
          </li>
          <ng-container *ngIf="authService.isAllowed('REFERENCES::CAN_MANAGE')">
            <li class="nav-item" *ngIf="authService.isAllowed('REFERENCES::CAN_MANAGE_BIOMETRICS')">
              <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/references/biometrics']">
                <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <span class="fa-solid fa-fingerprint fa-lg fa-fw"></span>
                </div>
                <span class="nav-link-text ms-1">Biometrics</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="authService.isAllowed('REFERENCES::CAN_MANAGE_COMPANIES')">
              <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/references/companies']">
                <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <span class="fa-solid fa-building fa-lg fa-fw"></span>
                </div>
                <span class="nav-link-text ms-1">Companies</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/references/departments']">
                <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <span class="fa-solid fa-people-roof fa-lg fa-fw"></span>
                </div>
                <span class="nav-link-text ms-1">Departments</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/references/holidays']">
                <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <span class="fa-solid fa-calendar-day fa-lg fa-fw"></span>
                </div>
                <span class="nav-link-text ms-1">Holidays</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/references/job-descriptions']">
                <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <span class="fa-solid fa-briefcase fa-lg fa-fw"></span>
                </div>
                <span class="nav-link-text ms-1">Job Descriptions</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="authService.isAllowed('REFERENCES::CAN_MANAGE_ROLE_PERMISSIONS')">
              <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/references/role-permissions']">
                <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <span class="fa-solid fa-user-shield fa-lg fa-fw"></span>
                </div>
                <span class="nav-link-text ms-1">Role Permissions</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="authService.isAllowed('REFERENCES::CAN_MANAGE_SECURITY_ACCESSES')">
              <a class="nav-link" [routerLinkActive]="['text-primary', 'active']" [routerLink]="['/management/references/security-access']">
                <div class="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <span class="fa-solid fa-shield-halved fa-lg fa-fw"></span>
                </div>
                <span class="nav-link-text ms-1">Security Accesses</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
      <hr class="horizontal dark mb-0">
      <div class="version-box">
        <div class="version-box-item" *ngIf="(website_version$ | async) != null">
          <div class="version-number">{{ website_version$ | async }}</div>
          <div class="version-of">Website Version</div>
        </div>
        <div class="version-box-item" *ngIf="(api_version$ | async) != null">
          <div class="version-number">{{ api_version$ | async }}</div>
          <div class="version-of">API Version</div>
        </div>
      </div>
    </div>
  </aside>
  <main class="main-content position-relative border-radius-lg">
    <nav class="navbar navbar-main navbar-expand-lg bg-success position-sticky top-0 px-4 shadow-none" id="navbarBlur" data-scroll="false" style="z-index: 10;">
      <div class="container-fluid py-1 px-0 px-xl-3 mt-3">
        <div class="d-flex flex-row">
          <ul class="navbar-nav me-2">
            <li class="nav-item d-xl-none d-flex align-items-center">
              <a class="nav-link text-white p-3 d-flex align-items-center h-100" (click)="toggleSidenav($event)">
                <div class="sidenav-toggler-inner">
                  <i class="sidenav-toggler-line bg-white"></i>
                  <i class="sidenav-toggler-line bg-white"></i>
                  <i class="sidenav-toggler-line bg-white"></i>
                </div>
              </a>
            </li>
          </ul>
          <div class="align-self-center">
            <ol class="breadcrumb bg-transparent mb-0 pb-0 pt-1 px-0 me-sm-6 me-5 d-none d-sm-flex">
              <li class="breadcrumb-item text-sm text-white" *ngFor="let bc of (module_breadcrumbs$ | async); let i = index;" [ngClass]="i == module_breadcrumbs$.getValue().length - 1 ? 'active' : 'opacity-5'">{{ bc }}</li>
            </ol>
            <h6 class="font-weight-bolder text-white mb-0 d-none d-sm-block">{{ module_title | async }}</h6>
            <h6 class="font-weight-bolder text-white mb-0 d-block d-sm-none text-wrap lh-1-25">
              <small>{{ module_title | async }}</small>
            </h6>
          </div>
        </div>
        <div class="collapse navbar-collapse justify-content-end me-md-0 me-sm-4" id="navbar">
          <ul class="navbar-nav justify-content-end">
            <li class="nav-item dropdown d-flex align-items-center">
              <a class="nav-link text-white p-3 h-100" data-bs-toggle="dropdown" tooltip="Notes">
                <div class="d-flex align-items-center h-100 w-100">
                  <span class="fa-solid fa-note-sticky fa-fw"></span>
                </div>
              </a>
              <ul class="notifs-dropdown dropdown-menu dropdown-menu-end">
                <li class="position-sticky sticky-top">
                  <div class="dropdown-header bg-white">Notes</div>
                </li>
                <li>
                  <div class="text-center pb-2 px-3">
                    <button class="btn btn-success btn-sm w-100" (click)="addNote()">
                      <span class="fa-solid fa-plus fa-fw"></span>
                      <span class="ms-2">Add Note</span>
                    </button>
                  </div>
                </li>
                <li *ngIf="(is_loading_notes$ | async)">
                  <div class="text-center py-3">
                    <span class="fa-solid fa-spinner fa-pulse fa-fw"></span>
                  </div>
                </li>
                <ng-container *ngIf="!(is_loading_notes$ | async)">
                  <ng-container *ngIf="notes.length > 0; else alterNotesContent">
                    <li class="px-1" *ngFor="let item of notes">
                      <a class="dropdown-item p-2" (click)="viewEditNote(item)">
                        <div class="notif">
                          <div class="notif-content">
                            <div class="notif-title">{{ !!item.NoteTitle ? item.NoteTitle : 'Untitled Note' }}</div>
                            <div class="notif-subtitle" *ngIf="item.DateUpdated != null">
                              <small class="text-muted" [attr.title]="item.FormattedDateUpdated">Updated {{ item.FormattedDateUpdatedAgo }}</small>
                            </div>
                            <div class="notif-subtitle" *ngIf="item.DateUpdated == null && item.DateCreated != null">
                              <small class="text-muted" [attr.title]="item.FormattedDateCreated">Created {{ item.FormattedDateCreatedAgo }}</small>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ng-container>
                  <ng-template #alterNotesContent>
                    <li>
                      <div class="text-center py-2">None at the moment.</div>
                    </li>
                  </ng-template>
                </ng-container>
              </ul>
            </li>

            <li class="nav-item dropdown d-flex align-items-center">
              <a class="nav-link text-white p-3 h-100" data-bs-toggle="dropdown" tooltip="Notifications">
                <div class="d-flex align-items-center h-100 w-100">
                  <div class="notif-icon" [ngClass]="[(unread_in_app_notifications_count$ | async) > 0 ? 'new-notif' : '']">
                    <span class="fa-solid fa-bell fa-fw" [ngClass]="[((unread_in_app_notifications_count$ | async) > 0 ? 'fa-shake' : '')]"></span>
                  </div>
                </div>
              </a>
              <ul class="notifs-dropdown dropdown-menu dropdown-menu-end">
                <li class="position-sticky sticky-top">
                  <div class="dropdown-header bg-white">Notifications</div>
                </li>
                <li *ngIf="(in_app_notifications$ | async) == null">
                  <div class="text-center py-3">
                    <span class="fa-solid fa-spinner fa-pulse fa-fw"></span>
                  </div>
                </li>
                <ng-container *ngIf="(in_app_notifications$ | async) != null">
                  <ng-container *ngIf="(in_app_notifications$ | async).length > 0; else alterNotificationsContent">
                    <li class="px-1" *ngFor="let item of (in_app_notifications$ | async)">
                      <div class="dropdown-item p-2" [ngClass]="[(!item.IsRead ? 'unread' : '')]">
                        <div class="notif">
                          <div class="notif-content">
                            <div class="notif-title" [innerHTML]="item.FormattedMessage"></div>
                            <div class="notif-subtitle" *ngIf="item.DateCreated != null">
                              <small class="text-muted" [attr.title]="item.FormattedDateCreated">{{ item.FormattedDateCreatedAgo }}</small>
                            </div>
                          </div>
                          <a class="cursor-pointer ms-2" (click)="markNotificationAsRead($event, item.Id)" title="Mark as Read" *ngIf="!item.IsRead">
                            <span class="fa-solid fa-eye fa-fw"></span>
                          </a>

                          <a class="cursor-pointer ms-2" (click)="markNotificationAsUnread($event, item.Id)" title="Mark as Unread" *ngIf="item.IsRead">
                            <span class="fa-solid fa-eye-slash fa-fw"></span>
                          </a>
                        </div>
                      </div>
                    </li>
                  </ng-container>
                  <ng-template #alterNotificationsContent>
                    <li>
                      <div class="text-center py-2">None at the moment.</div>
                    </li>
                  </ng-template>
                </ng-container>
              </ul>
            </li>
            <li class="nav-item dropdown d-flex align-items-center">
              <a class="nav-link text-white p-3 h-100" data-bs-toggle="dropdown">
                <div class="d-flex align-items-center h-100 w-100">
                  <div class="icon icon-shape icon-sm bg-gradient-success border border-2 border-white me-sm-2 d-inline-flex align-items-center justify-content-center shadow-danger text-center rounded-circle overflow-hidden">
                    <img [src]="utilityService.getApiUrl() + '/Storage/Images/Employees/' + profile_picture" class="profile-img" *ngIf="profile_picture != null" (error)="profile_picture = null">
                    <span class="fa-solid fa-user fa-fw text-white" *ngIf="profile_picture == null"></span>
                  </div>
                  <span class="d-none d-sm-inline-block me-sm-2">{{ auth.full_name }}</span>
                </div>
              </a>
              <ul class="dropdown-menu dropdown-menu-end p-2">
                <li>
                  <a class="dropdown-item border-radius-md" (click)="changePassword()">
                    <span class="fa-solid fa-key fa-fw"></span>
                    <span class="ms-2">Change Password</span>
                  </a>
                </li>
                <li><hr class="dropdown-divider"></li>
                <li>
                  <a class="dropdown-item border-radius-md" (click)="logout()">
                    <span class="fa-solid fa-right-from-bracket fa-fw"></span>
                    <span class="ms-2">Sign Out</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <router-outlet></router-outlet>
  </main>
</div>
