import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageNavigationService {
  pages$: BehaviorSubject<any> = new BehaviorSubject<any>({
    'employee-management': {
      page: null,
      search: null
    }
  });

  constructor() { }

  clearAll(callback: Function = null) {
    let temp = this.pages$.getValue();

    Object.keys(temp).forEach((key: any) => {
      this.setPage(key, null);
      this.setSearch(key, null);
    });

    this.pages$.next(temp);

    if(callback != null) {
      setTimeout(() => {
        callback();
      }, 1000);
    }
  }

  getPage(key: any) {
    let temp = this.pages$.getValue();

    return temp[key].page;
  }

  getSearch(key: any) {
    let temp = this.pages$.getValue();

    return temp[key].search;
  }

  setPage(key: any, value: any) {
    let temp = this.pages$.getValue();

    temp[key].page = value;

    this.pages$.next(temp);
  }

  setSearch(key: any, value: any) {
    let temp = this.pages$.getValue();

    temp[key].search = value;

    this.pages$.next(temp);
  }
}
