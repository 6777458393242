<div class="p-4">
  <h5 class="text-capitalize m-0">{{ (is_edit_mode$ | async) ? 'Edit' : 'View' }} Note</h5>
  <hr>
  <div class=" overflow-auto">
    <ng-container *ngIf="(is_edit_mode$ | async); else alterViewContent">
      <form [formGroup]="save_form">
        <div class="form-group">
          <label for="" class="mb-1">Note Title:</label>
          <input type="text" class="form-control form-control-lg" formControlName="NoteTitle">
        </div>
        <div class="form-group">
          <quill-editor
            formControlName="NoteContent"
            [modules]="utilityService.quillModules"
            theme="snow"
            [styles]="{ color: '#344767', height: '40vh' }"
          ></quill-editor>
        </div>
      </form>
    </ng-container>
    <ng-template #alterViewContent>
      <h3 class="fw-normal text-center">{{ !!note.NoteTitle ? note.NoteTitle : 'Untitled Note' }}</h3>
      <div class="blockquote p-0">
        <quill-view-html [content]="note.NoteContent" theme="snow"></quill-view-html>
      </div>
    </ng-template>
  </div>
  <hr>
  <div class="d-flex justify-content-between text-end">
    <ng-container *ngIf="(is_edit_mode$ | async); else alterViewButtonsContent">
      <button type="button" class="btn btn-secondary" (click)="toggleEditMode()">
        <span class="fa-solid fa-times fa-fw"></span>
        <span class="ms-2">Cancel</span>
      </button>
      <button type="button" class="btn btn-success" (click)="saveChanges()">
        <span class="fa-solid fa-save fa-fw"></span>
        <span class="ms-2">Save</span>
      </button>
    </ng-container>
    <ng-template #alterViewButtonsContent>
      <button type="button" class="btn btn-danger" (click)="deleteNote(note)">
        <span class="fa-solid fa-trash fa-fw"></span>
        <span class="ms-2">Delete</span>
      </button>
      <button type="button" class="btn btn-success" (click)="toggleEditMode()">
        <span class="fa-solid fa-edit fa-fw"></span>
        <span class="ms-2">Edit</span>
      </button>
    </ng-template>
  </div>
</div>
