import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-search-employee',
  templateUrl: './search-employee.component.html',
  styleUrls: ['./search-employee.component.scss']
})
export class SearchEmployeeComponent implements OnInit {
  id_number: any = null;
  employee: any = null;

  constructor(
    public utilityService: UtilityService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.id_number = this.route.snapshot.queryParams.id;

    if(this.id_number != null) {
      this.searchEmployee();
    }
  }

  searchEmployee() {
    this.spinner.show();

    this.utilityService.searchEmployee(this.id_number).subscribe((result: any) => {
      this.spinner.hide();

      result.BirthDate = this.utilityService.parseStamp(result.BirthDate).format('MMMM D, YYYY');
      result.DateOfEmployment = this.utilityService.parseStamp(result.DateOfEmployment).format('MMMM D, YYYY');

      if(result.LastDayOfEmployment != null) {
        result.FormattedLastDayOfEmployment = this.utilityService.parseStamp(result.LastDayOfEmployment).format('MMMM D, YYYY');
      }

      this.employee = result;
    }, (error) => {
      this.spinner.hide();
    });
  }
}
