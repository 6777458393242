import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { GuestGuard } from './guards/guest.guard';
import { MaintenanceGuard } from './guards/maintenance.guard';
import { ManagementComponent } from './pages/management/management.component';
import { SearchEmployeeComponent } from './pages/search-employee/search-employee.component';
import { NotFoundComponent } from './pages/status/not-found/not-found.component';
import { UnderMaintenanceComponent } from './pages/status/under-maintenance/under-maintenance.component';
import { TakeExaminationComponent } from './pages/take-examination/take-examination.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'search-employee',
    component: SearchEmployeeComponent
  },
  {
    path: 'take-examination',
    canActivateChild: [MaintenanceGuard],
    component: TakeExaminationComponent
  },
  {
    path: 'auth',
    canActivate: [GuestGuard],
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'management',
    component: ManagementComponent,
    canActivateChild: [AuthGuard, MaintenanceGuard],
    loadChildren: () => import('./pages/management/management.module').then(m => m.ManagementModule)
  },
  {
    path: 'under-maintenance',
    component: UnderMaintenanceComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
