import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { ExaminationService } from 'src/app/services/examination.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-take-examination',
  templateUrl: './take-examination.component.html',
  styleUrls: ['./take-examination.component.scss']
})
export class TakeExaminationComponent implements OnInit {
  auth: any = null;
  examination: any = null;
  questions: any = [];
  short_answer: string = '';
  display_index: number = 0;
  is_started: boolean = false;
  is_finished: boolean = false;
  is_processing: boolean = false;
  original_remaining_time: any = [15, 25];
  remaining_time: any = 0;
  timer_interval: any = null;
  exam_result: any = null;
  exam_answers: any = [];

  @ViewChild('answerField') answer_field: ElementRef;

  constructor(
    public utilityService: UtilityService,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private examinationService: ExaminationService
  ) { }

  ngOnInit(): void {
    this.auth = this.authService.getUser();

    this.getExamination(() => {
      this.getQuestionList();
    });
  }

  getExamination(callback: Function = null) {
    this.spinner.show();

    this.examinationService.getExamination(this.auth.id_number, this.utilityService.nowInPh().format('YYYY-MM-DD HH:mm:ss')).subscribe((result: any) => {
      this.spinner.hide();

      this.examination = result;

      if(callback != null) {
        callback();
      }
    }, (error) => {
      this.spinner.hide();

      this.examination = null;
    });
  }

  getQuestionList(callback: Function = null) {
    this.spinner.show();

    this.examinationService.getExaminationQuestion(this.examination.Id).subscribe((result: any) => {
      this.spinner.hide();

      this.questions = this.utilityService.shuffle(result.map((item: any) => {
        if(item.Type == 'MULTIPLE_CHOICE') {
          item.QuestionChoices = this.utilityService.shuffle(item.QuestionChoices);
        }

        return item;
      }));

      if(callback != null) {
        callback();
      }
    }, (error) => {
      this.spinner.hide();
    });
  }

  startExamination() {
    this.spinner.show();

    this.examinationService.startExamination(this.examination.Id).subscribe((result: any) => {
      this.spinner.hide();

      this.is_started = true;

      this.remaining_time = this.questions[this.display_index].Type == 'ENUMERATION' ? this.original_remaining_time[1] : this.original_remaining_time[0];

      this.setTimer();

      if(this.questions[this.display_index].Type != 'MULTIPLE_CHOICE') {
        this.focusAnswerInput();
      }
    }, (error) => {
      this.spinner.hide();

      this.utilityService.toast({
        icon: 'error',
        title: error.error
      });
    });
  }

  focusAnswerInput() {
    setTimeout(() => {
      if(this.answer_field != null) {
        this.answer_field.nativeElement.focus();
      }
    }, 0);
  }

  setTimer() {
    this.timer_interval = setInterval(() => {
      this.remaining_time -= 1;

      if(this.remaining_time == 0) {
        clearInterval(this.timer_interval);

        this.timer_interval = null;

        setTimeout(() => {
          this.remaining_time = this.questions[this.display_index].Type == 'ENUMERATION' ? this.original_remaining_time[1] : this.original_remaining_time[0];

          this.setAnswer(null, null);
        }, 500);
      }
    }, 1000);
  }

  setAnswer(body: any, image: any) {
    let displayed_question = this.questions[this.display_index];

    this.exam_answers.push({
      ExaminationId: displayed_question.ExaminationId,
      ExaminationQuestionId: displayed_question.Id,
      Body: body,
      Image: image
    });

    if(!this.is_finished && this.display_index < this.questions.length - 1) {
      this.display_index += 1;

      clearInterval(this.timer_interval);

      this.timer_interval = null;
      this.remaining_time = this.questions[this.display_index].Type == 'ENUMERATION' ? this.original_remaining_time[1] : this.original_remaining_time[0];

      this.setTimer();

      if(this.questions[this.display_index].Type != 'MULTIPLE_CHOICE') {
        this.focusAnswerInput();
      }
    } else {
      this.is_finished = true;

      clearInterval(this.timer_interval);

      this.timer_interval = null;

      this.processExamination();
    }
  }

  processExamination() {
    this.is_processing = true;

    this.examinationService.processExamination(this.exam_answers).subscribe((result: any) => {
      this.is_processing = false;

      this.exam_result = result;
    });
  }
}
