import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UtilityService } from '../services/utility.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private authService: AuthService,
    private utilityService: UtilityService
  ) { }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve: any) => {
      this.utilityService.getSettings('MaintenanceMode')
        .toPromise()
        .then((result: any) => {
          if(!this.authService.isLoggedIn()
            || (this.authService.isLoggedIn() && this.authService.getUser().account_role != "ADMINISTRATOR")
          ) {
            if(result.Bit != null && result.Bit == true) {
              this.router.navigate(['/under-maintenance']);

              resolve(false);
            }
          }

          resolve(true);
        })
        .catch(() => {
          resolve(true);
        });
    });
  }
}
