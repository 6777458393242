import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { version } from 'package.json';
import { SwPush } from '@angular/service-worker';

export const VAPID_KEY = environment.vapid_key;
export const API_URL = environment.api_url;
export const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  }),
};

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(
    private http: HttpClient,
    private swPush: SwPush
  ) { }

  websiteVersion() {
    return version;
  }

  getApiVersion() {
    return this.http.get(`${API_URL}/data/version`, HTTP_OPTIONS);
  }

  getApiUrl() {
    return API_URL.substring(0, API_URL.length - 4);
  }

  getSettings(key: string) {
    return this.http.get(`${environment.api_url}/settings/get?key=${key}`, HTTP_OPTIONS);
  }

  setSettings(key: string, val: string = null, bit: boolean = null) {
    return this.http.post(`${environment.api_url}/settings/set`, {
      ConfigurationKey: key,
      ConfigurationValue: val,
      ConfigurationBit: bit
    }, HTTP_OPTIONS);
  }

  async requestNotificationPermission(auth: any, callback: Function = null) {
    let permission = await Notification.requestPermission();

    if(permission === 'granted' && this.swPush.isEnabled && auth != null) {
      this.swPush.requestSubscription({
        serverPublicKey: VAPID_KEY
      }).then((subscriber: any) => {
        let sub = subscriber.toJSON();

        this.sendSubscriber(sub.endpoint, sub.keys.p256dh, sub.keys.auth, sub.expirationTime, auth.id_number).subscribe();

        if(callback != null) {
          callback();
        }
      });
    }
  }

  test() {
    return this.http.get(`${API_URL}/data/test`, HTTP_OPTIONS);
  }

  copyToClipboard(text) {
    window.navigator.clipboard.writeText(text);

    this.toast({
      icon: 'success',
      title: 'Copied to Clipboard.'
    });
  }

  parseSmsResponse(response: any) {
    let parsed_response: any = {};
    let chunks = response.toString().replace(/\u0000/gi, '').split('\r\n');

    chunks.forEach((chunk: any) => {
      let temp = chunk.trim();

      if(temp != '') {
        if(!(/^(--).*(--)$/gi).test(temp)) {
          let key = temp.split(':')[0].trim();
          let value = temp.split(':').slice(1).join(':').trim();

          parsed_response[key.toLowerCase()] = value;
        }
      }
    });

    return parsed_response;
  }

  dataUrlToFile(data_url: string, filename: string = null) {
    let arr = data_url.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let base_string = atob(arr[1]);
    let n = base_string.length;
    let uint8arr = new Uint8Array(n);

    while (n--) {
      uint8arr[n] = base_string.charCodeAt(n);
    }

    if(filename == null) {
      let filetype = '';

      switch(mime) {
        case 'image/gif':
          filetype = '.gif';

          break;
        case 'image/jpeg':
          filetype = '.jpg';

          break;
        case 'image/png':
          filetype = '.png';

          break;
        case 'image/svg+xml':
          filetype = 'svg';

          break;
        case 'image/webp':
          filetype = 'webp';

          break;
        default:
          break;
      }

      filename = `image${filetype}`;
    }

    return (new File([uint8arr], filename, {
      type: mime
    }));
  }

  searchEmployee(id_number: any) {
    return this.http.get(`${API_URL}/data/search-employee/${id_number}`, HTTP_OPTIONS);
  }

  sendSubscriber(endpoint: string, key: string, auth: string, expiration: any = null, id_number: any = null) {
    return this.http.post(`${API_URL}/notification/subscriber/add`, {
      Endpoint: endpoint,
      PushKey: key,
      PushAuth: auth,
      Expiration: expiration,
      Idnumber: id_number
    }, HTTP_OPTIONS);
  }

  getIpAddress() {
    return this.http.get('https://api.ipify.org?format=json');
  }

  toTitleCase(text: string) {
    return text.substring(0, 1).toUpperCase() + text.substring(1).toLowerCase();
  }

  nowInPh(): any {
    return moment().tz('Asia/Manila');
  }

  nowInUTC(): any {
    return moment().utc();
  }

  parseStamp(stamp: any) {
    return moment(stamp);
  }

  parseTime(time: any, custom_format: string = 'HH:mm:ss') {
    return moment(`1970-01-01 ${time}`).format(custom_format);
  }

  stampToUTC(stamp: any = null) {
    if(stamp == null) {
      return moment().utc();
    }

    return moment(stamp).utc();
  }

  stampToTz(timezone: any, stamp: any = null) {
    if(stamp == null) {
      return this.stampToUTC().tz(timezone);
    }

    return this.stampToUTC(stamp).tz(timezone);
  }

  stampToPh(stamp: any = null) {
    if(stamp == null) {
      return this.stampToTz('Asia/Manila');
    }

    return this.stampToTz('Asia/Manila', stamp);
  }

  stampFromPhToUTC(stamp: any) {
    return moment(`${stamp}+0800`).utc();
  }

  stampFromUTCToPh(stamp: any) {
    return moment(`${stamp}Z`).tz('Asia/Manila');
  }

  parseTimeFromPhToUTC(time: any, formatted: boolean = true): any {
    if(formatted) {
      return moment(`1970-01-01T${time}+0800`).utc().format('HH:mm:ss');
    }

    return moment(`1970-01-01T${time}+0800`).utc();
  }

  parseTimeFromUTCToPh(time: any, formatted: boolean = true): any {
    if(formatted) {
      return moment(`1970-01-01T${time}Z`).tz('Asia/Manila').format('HH:mm:ss');
    }

    return moment(`1970-01-01T${time}Z`).tz('Asia/Manila');
  }

  charToInt(char: string, index: number = 0) {
    return char.charCodeAt(index);
  }

  intToChar(...code: number[]) {
    return String.fromCharCode(...code);
  }

  shuffle(arr: any[]) {
    for(let i = arr.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let temp = arr[i];

      arr[i] = arr[j];
      arr[j] = temp;
    }

    return arr;
  }

  generateDutyTimeOut(day_of_week_in: any, time_in: any, hours_to_render: number = 8, hours_of_break: number = 1) {
    let stamp = this.stampFromPhToUTC(`1970-01-01T${time_in.substring(0, 5)}`);
    let new_stamp = stamp.clone().add(hours_to_render, 'hours').add(hours_of_break, 'hours');

    stamp = stamp.tz('Asia/Manila');
    new_stamp = new_stamp.tz('Asia/Manila');

    let dw = parseInt(new_stamp.format('d')) - parseInt(stamp.format('d'));

    day_of_week_in = parseInt(day_of_week_in);

    return {
      day_of_week_out: parseInt(day_of_week_in + dw <= 6 ? day_of_week_in + dw : 0),
      time_out: new_stamp.format('HH:mm')
    }
  }

  canOfficersManageTeam(user: any, can_manage_who: any) {
    let can_manage = false;

    if(user.AsOfficerInCharge.length > 0) {
      user.AsOfficerInCharge.forEach((user_team_id: any) => {
        if(can_manage_who.AsOfficerInCharge.length > 0) {
          can_manage_who.AsOfficerInCharge.forEach((cmo_team_id: any) => {
            if(user_team_id == cmo_team_id) {
              can_manage = true;
            }
          });
        }

        if(can_manage_who.AsAssistantOfficerInCharge.length > 0) {
          can_manage_who.AsAssistantOfficerInCharge.forEach((cmo_team_id: any) => {
            if(user_team_id == cmo_team_id) {
              can_manage = true;
            }
          });
        }

        if(can_manage_who.AsMember.length > 0) {
          can_manage_who.AsMember.forEach((cmo_team_id: any) => {
            if(user_team_id == cmo_team_id) {
              can_manage = true;
            }
          });
        }
      });
    }

    if(user.AsAssistantOfficerInCharge.length > 0) {
      user.AsAssistantOfficerInCharge.forEach((user_team_id: any) => {
        if(can_manage_who.AsOfficerInCharge.length > 0) {
          can_manage_who.AsOfficerInCharge.forEach((cmo_team_id: any) => {
            if(user_team_id == cmo_team_id) {
              can_manage = true;
            }
          });
        }

        if(can_manage_who.AsAssistantOfficerInCharge.length > 0) {
          can_manage_who.AsAssistantOfficerInCharge.forEach((cmo_team_id: any) => {
            if(user_team_id == cmo_team_id) {
              can_manage = true;
            }
          });
        }

        if(can_manage_who.AsMember.length > 0) {
          can_manage_who.AsMember.forEach((cmo_team_id: any) => {
            if(user_team_id == cmo_team_id) {
              can_manage = true;
            }
          });
        }
      });
    }

    return can_manage;
  }

  getSession(key: string) {
    return localStorage.getItem(key);
  }

  setSession(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  toast(params: any, mixin_params: any = null) {
    let toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: false,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    if(mixin_params != null) {
      toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        ...mixin_params
      });
    }

    return toast.fire({
      showCloseButton: true,
      ...params
    });
  }

  prompt(params: any) {
    return Swal.fire(params);
  }

  get quillModules() {
    return {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['code-block'],
        ['clean']
      ]
    };
  }

  sortObjAscending(key: string, a: any, b: any) {
    if(a[key] < b[key]) {
      return -1;
    }

    if(a[key] > b[key]) {
      return 1;
    }

    return 0;
  }

  sortObjDescending(key: string, a: any, b: any) {
    if(a[key] < b[key]) {
      return 1;
    }

    if(a[key] > b[key]) {
      return -1;
    }

    return 0;
  }
}
